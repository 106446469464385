import axios from 'axios';
import { CONTRAOFERTA_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr)
axios.interceptors.response.use(resFilter, resErr)

export const listarContraofertasRecibidas = async (idPedido) => {
  return axios.get(`${CONTRAOFERTA_PATH}/listar/recibidas?idPedido=${idPedido}`);
};

export const guardarContraoferta = async (contraoferta) => {
  return axios.post(`${CONTRAOFERTA_PATH}/guardar`, contraoferta);
};

export const aceptarAsignacionDeprecate = async (contraoferta) => {
  return axios.post(`${CONTRAOFERTA_PATH}/aceptar-asignacion`, contraoferta);
};

export const aceptarAsignacion = async (id) => {
  return axios.post(`${CONTRAOFERTA_PATH}/aceptar?idCarga=${id}`);
};

export const aceptarContraoferta = async (id,notificar) => {
  return axios.put(`${CONTRAOFERTA_PATH}/aceptar?id=${id}&notificar=${notificar}`);
};

export const rechazarContraoferta = async (id) => {
  return axios.post(`${CONTRAOFERTA_PATH}/desestimar?id=${id}`);
}